<template>
  <v-dialog
    class="b-dialog"
    transition="dialog-bottom-transition"
    persistent
    :max-width="400"
    :retain-focus="false"
    :value="dialog"
    @input="closeModal(false)"
  >
    <v-form
      @submit.prevent="execute"
      ref="form"
      lazy-validation
      v-model="valid"
    >
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>Qual o motivo da inativação?</v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            id="btn-close"
            name="btn-close"
            @click="closeModal(false)"
          >
            <v-icon>fa-solid fa-xmark</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="b-dialog--content">
          <v-row dense>
            <v-col cols="12" xl="12" lg="12" md="12" sm="12">
              <v-autocomplete
                id="cancellationReason"
                name="cancellationReason"
                label="Motivo"
                outlined
                dense
                required
                :rules="[required]"
                :items="items"
                v-model="model.cancellationReason"
              />
            </v-col>
            <v-col cols="12" xl="12" lg="12" md="12" sm="12">
              <BaseDatePicker
                id="inactivityDate"
                name="inactivityDate"
                label="Data de inativação"
                required
                :rules="[required]"
                v-model="model.inactivityDate"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="b-dialog--actions">
          <BaseButton
            id="btn-save"
            name="btn-save"
            type="submit"
            color="primary"
            :disabled="!valid"
            title="Enviar"
          />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { showMessage } from '@/helpers/messages';
import { ContractService } from '@/services/api/contracts';

export default {
  mixins: [rulesMixin],

  props: {
    dialog: {
      type: Boolean,
      default: false,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  },

  data: () => ({
    valid: true,
    model: {
      cancellationReason: null,
      inactivityDate: null
    },
    items: [
      'Cancelamento de Contrato',
      'Transferência de Corretagem',
      'Falência da Empresa',
      'Reimplantação'
    ]
  }),

  methods: {
    closeModal(redirect) {
      this.$refs.form.reset();
      this.$emit('close', redirect);
    },

    async execute() {
      if (!this.$refs.form.validate(true)) {
        return;
      } else {
        try {
          const contractService = new ContractService();
          const { status } = await contractService.cancel({
            id: this.id,
            ...this.model
          });

          if (status === 204) {
            showMessage('success', 'Contrato cancelado com sucesso!');
            this.closeModal(true);
          }
        } catch (error) {
          throw new Error(error);
        }
      }
    }
  }
};
</script>
